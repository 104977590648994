<template>
  <div class="object-couplings flex">
    <div
      class="object-couplings__table-wrapper flex flex-col gap-4 w-7/12 pt-4"
    >
      <div class="flex justify-between items-start px-5">
        <span class="text-base">{{
          $t('new_units.objects.fields.couplings.title')
        }}</span>
        <UnitsAddButton :active-tab="activeTab" @click.native="addCoupling" />
      </div>
      <div class="px-5">
        <UnitsDateTimePicker
          class="object-couplings__date-filter"
          @onChange="changePeriod"
        />
      </div>

      <UnitsTable
        :table-data="couplingsTable.newList"
        :titles="couplingsTable.titles"
        :options="couplingsTable.options"
        class="object-couplings__new-table"
        @onRowClick="rowClick"
        @onDelete="deleteCoupling"
      />
      <div class="object-couplings__approved-table">
        <ObjectsNewElementsHeader
          v-if="couplingsTable.list.length"
          class="px-5"
        />
        <UnitsTable
          :show-header="false"
          :table-data="couplingsTable.list"
          :titles="couplingsTable.titles"
          :options="couplingsTable.options"
          :empty="emptyData"
          class="object-couplings__table"
          @onRowClick="rowClick"
          @onDelete="deleteCoupling"
        />
      </div>
      <UnitsDeletedRecovery
        v-if="couplingsTable.options.recovered.length"
        :count="couplingsTable.options.recovered.length"
        :active-tab="activeTab"
        @onCancel="cancelDeletion"
        @onDeleteRecovered="finalDeletion"
      />
    </div>
    <CouplingsDetails
      v-if="isNewCoupling || selectedCoupling"
      :coupling="selectedCoupling"
      :errors="errorData"
      class="w-5/12"
      @onApprove="approveCoupling"
      @onUpdate="updateCoupling"
      @onCancel="cancelChanges"
    />
  </div>
</template>
<script setup>
import { onBeforeMount, ref, watch, getCurrentInstance } from 'vue'

import { createVuexHelpers } from 'vue2-helpers'
import { useI18n } from '@/hooks/useI18n'
import { unitTrailersApi } from '@/api'
import { refs } from '@/components/unitsNew/helpers/index.js'

import CouplingsDetails from './CouplingsDetails.vue'
import UnitsTable from '@/components/unitsNew/components/UnitsTable.vue'
import UnitsAddButton from '@/components/unitsNew/components/UnitsAddButton.vue'
import UnitsDateTimePicker from '@/components/unitsNew/components/UnitsDateTimePicker.vue'
import UnitsDeletedRecovery from '@/components/unitsNew/components/UnitsDeletedRecovery.vue'
import ObjectsNewElementsHeader from '@/components/unitsNew/components/Objects/ObjectsNewElementsHeader.vue'

const { useGetters, useMutations } = createVuexHelpers()

const {
  activeObject,
  activeObjectCouplings,
  activeObjectNewCouplings,
  modalType
} = useGetters('units', [
  'activeObject',
  'activeObjectCouplings',
  'activeObjectNewCouplings',
  'modalType'
])

const {
  SET_COUPLINGS_ACTIVE_OBJECT,
  SET_NEW_COUPLINGS_ACTIVE_OBJECT,
  SET_ERRORS
} = useMutations('units', [
  'SET_COUPLINGS_ACTIVE_OBJECT',
  'SET_NEW_COUPLINGS_ACTIVE_OBJECT',
  'SET_ERRORS'
])

const $t = useI18n()
const emits = defineEmits(['changePeriod'])
const instance = getCurrentInstance()
const activeTab = 'couplings'

const couplingsTable = ref({
  list: [],
  newList: [],
  titles: [
    {
      label: $t('new_units.objects.fields.couplings.table_columns.from'),
      prop: 'date_from',
      width: '30',
      headerAlign: 'center',
      align: 'center',
      icon: true,
      class: []
    },
    {
      label: $t('new_units.objects.fields.couplings.table_columns.to'),
      prop: 'date_to',
      width: '30',
      headerAlign: 'center',
      align: 'center',
      icon: true,
      class: []
    },
    {
      label: $t('new_units.objects.fields.couplings.table_columns.trailer'),
      prop: 'trailer.name',
      width: '30',
      headerAlign: 'center',
      align: 'center',
      icon: true,
      class: ['greyToneThird', 'max-w-textShort']
    },
    {
      label: '',
      prop: 'delete',
      width: '10',
      headerAlign: 'center',
      align: 'center'
    }
  ],
  options: {
    hasCheckboxColumn: false,
    recovered: []
  }
})

const emptyData = {
  icon: activeTab,
  text: $t('new_units.objects.fields.couplings.no_couplings')
}

const errorData = ref([])

const isNewCoupling = ref(false)
const selectedCoupling = ref(null)

const deleteTime = 10000
let myTimeout = null

const changePeriod = (date) => {
  isNewCoupling.value = false

  emits('changePeriod', date)
}

const cancelChanges = (coupling) => {
  selectedCoupling.value = coupling
}

const approveCoupling = async () => {
  await refs.couplingsValidation.value.validate(async (valid) => {
    if (valid) {
      await unitTrailersApi.createUnitTrailer(
        {
          ...selectedCoupling.value,
          id: null,
          unit: { id: activeObject.value.id }
        },
        (res) => {
          isNewCoupling.value = false

          errorData.value = []
          SET_ERRORS([])

          couplingsTable.value.list.push(res.data)
          couplingsTable.value.newList = []
          selectedCoupling.value = res.data

          SET_COUPLINGS_ACTIVE_OBJECT(couplingsTable.value.list)
          SET_NEW_COUPLINGS_ACTIVE_OBJECT(couplingsTable.value.newList)

          instance.proxy.$showSuccess(
            $t('new_units.objects.fields.couplings.success.approved')
          )
        },
        (error) => {
          errorData.value = error.response.data.errors.map((item) => item.field)
          SET_ERRORS([activeTab])

          instance.proxy.$showError(error.response.data.message)
        }
      )
    } else {
      return false
    }
  })
}

const addCoupling = () => {
  if (isNewCoupling.value)
    return instance.proxy.$showError(
      $t('new_units.objects.fields.couplings.errors.approved')
    )

  isNewCoupling.value = true

  const newCoupling = {
    id: Math.random().toString(),
    trailer: { id: '', name: $t('new_units.not_selected') },
    date_from: $t('new_units.unspecified'),
    date_to: $t('new_units.unspecified'),
    work_type: '',
    min_speed: '',
    max_speed: '',
    sensor_name_mask: '',
    isNew: true
  }

  couplingsTable.value.newList.push(newCoupling)

  selectedCoupling.value = newCoupling

  SET_NEW_COUPLINGS_ACTIVE_OBJECT(couplingsTable.value.newList)
}

const updateCoupling = () => {
  unitTrailersApi.updateUnitTrailer(
    selectedCoupling.value,
    () => {
      errorData.value = []
      SET_ERRORS([])
      instance.proxy.$showSuccess(
        $t('new_units.objects.fields.shifts.success.updated')
      )
    },
    (error) => {
      errorData.value = error.response.data.errors.map((item) => item.field)
      SET_ERRORS([activeTab])
      instance.proxy.$showError(error.response.data.message)
    }
  )
}

const finalDeletion = () => {
  if (!couplingsTable.value.options.recovered.length) return

  isNewCoupling.value = false

  couplingsTable.value.options.recovered.forEach(async (id) => {
    const deletedCoupling = couplingsTable.value.newList.find(
      (newCoupling) => newCoupling.id === id
    )

    if (deletedCoupling) {
      couplingsTable.value.newList = couplingsTable.value.newList.filter(
        (coupling) => coupling.id !== id
      )
    } else {
      await unitTrailersApi.deleteUnitTrailer(
        id,
        () => {
          couplingsTable.value.list = couplingsTable.value.list.filter(
            (coupling) => coupling.id !== id
          )
        },
        (error) => {
          instance.proxy.$showError(error.response.data.message)
        }
      )
    }
  })

  if (
    couplingsTable.value.options.recovered.includes(selectedCoupling.value.id)
  ) {
    errorData.value = []
    SET_ERRORS([])
  }

  couplingsTable.value.options.recovered = []
  selectedCoupling.value = null

  clearTimeout(myTimeout)

  SET_COUPLINGS_ACTIVE_OBJECT(couplingsTable.value.list)
  SET_NEW_COUPLINGS_ACTIVE_OBJECT(couplingsTable.value.newList)
}

const deletionTimeout = () => {
  myTimeout = setTimeout(finalDeletion, deleteTime)
}

const deleteCoupling = (coupling) => {
  if (couplingsTable.value.options.recovered.includes(coupling.item.id))
    return instance.proxy.$showError(
      $t('new_units.objects.fields.couplings.error.already_deleted')
    )

  couplingsTable.value.options.recovered.push(coupling.item.id)

  clearTimeout(myTimeout)
  deletionTimeout()
}

const cancelDeletion = () => {
  couplingsTable.value.options.recovered.splice(0)

  clearTimeout(myTimeout)
}

watch(activeObjectCouplings, (val) => {
  couplingsTable.value.list = val

  val.length
    ? (selectedCoupling.value = val[0])
    : (selectedCoupling.value = null)
})

const rowClick = (coupling) => {
  selectedCoupling.value = coupling
}

onBeforeMount(() => {
  if (modalType.value === 'edit') {
    couplingsTable.value.list = activeObjectCouplings.value
    couplingsTable.value.newList = activeObjectNewCouplings.value

    if (activeObjectCouplings.value.length)
      selectedCoupling.value = activeObjectCouplings.value[0]
  }
})
</script>
<style lang="scss">
@import '@/components/unitsNew/assets/styles/Objects/Couplings.scss';
</style>
